var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.title,
        size: "xl",
        "hide-header-close": "",
        centered: "",
        "cancel-variant": "light",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "4", md: "4", lg: "2", xl: "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "md", variant: "light", block: "" },
                          on: { click: _vm.emitEventClose },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(1, "Cancel")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "8", md: "8", lg: "3", xl: "3" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            size: "md",
                            variant: "primary",
                            disabled: _vm.watingSubmitForm,
                            block: "",
                          },
                          on: { click: _vm.dispatchEvent },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                            },
                            [
                              _c("b-spinner", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.watingSubmitForm,
                                    expression: "watingSubmitForm",
                                  },
                                ],
                                attrs: { small: "" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "pl-2",
                                  staticStyle: { "margin-top": "1px" },
                                },
                                [_vm._v(_vm._s(_vm.FormMSG(2, "Save")))]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "container-layout" },
        [
          _c("script-form", {
            ref: "form",
            attrs: {
              "display-submit-btn": false,
              action: _vm.action,
              item: _vm.item,
            },
            on: {
              "script-form:successed": _vm.reloadList,
              "script-form:loading": _vm.onLoaded,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }