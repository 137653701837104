<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="title"
		size="xl"
		hide-header-close
		centered
		cancel-variant="light"
		no-close-on-backdrop
		no-close-on-esc
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="container-layout">
			<script-form
				ref="form"
				:display-submit-btn="false"
				:action="action"
				:item="item"
				@script-form:successed="reloadList"
				@script-form:loading="onLoaded"
			/>
		</div>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-col sm="4" md="4" lg="2" xl="2">
					<b-button size="md" variant="light" @click="emitEventClose" block>
						{{ FormMSG(1, 'Cancel') }}
					</b-button>
				</b-col>
				<b-col sm="8" md="8" lg="3" xl="3">
					<b-button size="md" variant="primary" :disabled="watingSubmitForm" @click="dispatchEvent" block>
						<div class="d-flex justify-content-center align-items-center">
							<b-spinner v-show="watingSubmitForm" small />
							<div class="pl-2" style="margin-top: 1px">{{ FormMSG(2, 'Save') }}</div>
						</div>
					</b-button>
				</b-col>
			</div>
		</template>
	</b-modal>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import ScriptForm from '@/views/script/_id';

export default {
	name: 'ScriptFormModal',
	mixins: [languageMessages, globalMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: true,
			default: ''
		},
		item: {
			type: Object,
			required: true,
			default: () => {}
		},
		action: {
			type: String,
			required: true,
			default: ''
		}
	},
	components: {
		ScriptForm
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	data() {
		return {
			watingSubmitForm: false
		};
	},
	methods: {
		emitEventClose() {
			this.$emit('script-form-modal:closed');
		},
		dispatchEvent(e) {
			e.preventDefault();
			this.$refs['form'].$refs['submit'].click();
		},
		reloadList(payload) {
			if (payload) {
				this.$emit('script-form-modal:closed');
				this.$emit('script-form-modal:successed', payload);
			}
		},
		onLoaded(payload) {
			this.watingSubmitForm = payload;
		}
	}
};
</script>
